<template>
  <div v-if="problemData">
    <b-card title="Change Problem Status">
      <validation-observer ref="simpleRules">
        <b-form>
          <b-row>
            <b-col md="6">
              <validation-provider
                #default="{ errors }"
                name="Status"
                rules="required"
              >
                <b-form-group
                  label="Order Status"
                  label-for="Status"
                  :state="errors.length > 0 ? false : null"
                >
                  <v-select
                    id="status"
                    v-model="statusSelect"
                    :state="statusSelect === null ? false : true"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="statusOptions"
                    :disabled="problemData.status == 'closed'"
                    :clearable="false"
                    :selectable="
                      (option) => !option.value.includes('select_value')
                    "
                    label="text"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col md="6">
              <b-form-group label="Note" label-for="Note">
                <validation-provider
                  #default="{ errors }"
                  name="Note"
                  rules="required"
                >
                  <b-form-input
                    id="Note"
                    v-model="data.note"
                    :state="errors.length > 0 ? false : null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col cols="12">
              <b-button variant="primary" @click="submit">Submit</b-button>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
    </b-card>
  </div>
</template>

<script>
import { heightTransition } from "@core/mixins/ui/transition";

import { required } from "@validations";
export default {

  mixins: [heightTransition],
  data() {
    return {
      required,
      problemData: null,
      data: {
        note: "",
        status: "",
      },
      statusOptions: [],
    };
  },
  methods: {
    fetchData() {
      axios
        .get("problems/" + this.$route.params.id)
        .then((result) => {
          this.problemData = result.data.data;
          if (this.problemData.status == "pending") {
            this.statusOptions = [
              {
                text: "Opening",
                value: "opening",
              },
              {
                text: "Closed",
                value: "closed",
              },
            ];
            this.statusSelect = {
              text: "Pending",
              value: "pending",
            };
          } else if (this.problemData.status == "opening ") {
            this.statusOptions = [
              {
                text: "Closed",
                value: "closed",
              },
            ];
            this.statusSelect = {
              text: "Opening",
              value: "opening",
            };
          } else {
            this.statusSelect = {
              text: "Closed",
              value: "closed",
            };
          }
        })
        .catch((err) => {
          this.errors_back.length = 0;
          if (err.response.data.data != null) {
            if (this.isString(err.response.data.data)) {
              this.errors_back.push({
                error: err.response.data.data,
              });
            } else {
              this.errors_back = err.response.data.data;
            }
            this.showDismissibleAlert = true;
          } else {
            this.errors_back = [];
            this.errors_back.push({
              error: "internal server error",
            });
            this.showDismissibleAlert = true;
          }
        });
    },
    submit() {
      this.data.status = this.statusSelect.value;
      axios
        .post("problems/" + this.$route.params.id + "/change-status", this.data)
        .then((result) => {
          this.$swal({
            position: "center",
            icon: "success",
            title: "Your work has been saved",
            showConfirmButton: false,
            timer: 1500,
          });
          this.fetchData();
        })
        .catch((err) => {
          this.$swal({
            position: "center",
            icon: "error",
            title: "Error!",
            showConfirmButton: false,
            timer: 1500,
          });
          this.errors_back.length = 0;
          if (err.response.data.data != null) {
            if (this.isString(err.response.data.data)) {
              this.errors_back.push({
                error: err.response.data.data,
              });
            } else {
              this.errors_back = err.response.data.data;
            }
            this.showDismissibleAlert = true;
          } else {
            this.errors_back = [];
            this.errors_back.push({
              error: "internal server error",
            });
            this.showDismissibleAlert = true;
          }
        });
    },
  },
  created() {
    this.fetchData();
  },
};
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
